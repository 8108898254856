<section class="breadcrumbs">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <ol>
        <li><a routerLink="/">Home</a></li>
        <li><a routerLink="../">Video Library</a></li>
        <li>Videos</li>
      </ol>
    </div>
  </div>
</section>
<main id="main">
  <section id="services" class="services section-bg">
    <div class="container" data-aos="fade-up">
      <div class="row">
        <ng-container *ngFor="let video of videos"
          ><div
            class="col-lg-3 col-md-6 d-flex align-items-stretch mt-4 video-item"
          >
            <div class="icon-box">
              <div class="icon">
                <img [src]="trustImage(video.thumbnail)" alt="" />
              </div>
              <div class="video-item__title">
                <ng-container
                  *ngIf="
                    video?.fileStorageType === 'Internal File' ||
                      video?.offeringId;
                    else externalLinkBlock
                  "
                >
                  <h4>
                    <a
                      class="cut-text"
                      routerLink="video-play"
                      [queryParams]="{
                        link: filter === 'mw' ? video.videoFile : video.link
                      }"
                      >{{ video.name }}</a
                    >
                  </h4>
                </ng-container>
                <ng-template #externalLinkBlock>
                  <h4>
                    <a
                      class="cut-text"
                      href="{{
                        filter === 'mw' ? video.videoFile : video.link
                      }}"
                      target="_blank"
                      >{{ video.name }}</a
                    >
                  </h4>
                </ng-template>

                <div class="menu-container">
                  <button mat-icon-button [matMenuTriggerFor]="menu">
                    <mat-icon>more_vert</mat-icon>
                  </button>

                  <mat-menu #menu="matMenu">
                    <ng-container
                      *ngIf="
                        video?.fileStorageType === 'Internal File' ||
                          (video?.offeringId && video?.type === 'Video');
                        else externalBlock
                      "
                    >
                      <a
                        class="link"
                        routerLink="video-play"
                        [queryParams]="{
                          link: filter === 'mw' ? video.videoFile : video.link
                        }"
                      >
                        <button
                          mat-menu-item
                          class="link"
                          routerLink="video-play"
                          [queryParams]="{
                            link: filter === 'mw' ? video.videoFile : video.link
                          }"
                        >
                          Play
                        </button></a
                      >
                    </ng-container>
                    <ng-template #externalBlock>
                      <a
                        [href]="filter === 'mw' ? video.videoFile : video.link"
                        class="link"
                        target="_blank"
                      >
                        <button
                          mat-menu-item
                          [href]="
                            filter === 'mw' ? video.videoFile : video.link
                          "
                          class="link"
                          target="_blank"
                        >
                          Play
                        </button></a
                      >
                    </ng-template>

                    <a
                      [href]="trustDownload(video)"
                      class="link"
                      target="_blank"
                    >
                      <button
                        mat-menu-item
                        class="link"
                        *ngIf="
                          video?.fileStorageType === 'Internal File' ||
                          (video?.offeringId && video?.type === 'Video')
                        "
                      >
                        Download
                      </button></a
                    >
                    <button mat-menu-item (click)="handleShareWithVideo(video)">
                      <p class="link">Share with</p>
                    </button>
                  </mat-menu>
                </div>
              </div>
              <p class="cut-text">{{ video.description }}</p>
            </div>
          </div>
        </ng-container>
      </div>
    </div>
  </section>
  <!-- End Team Section -->

  <!-- ======= Clients Section ======= -->
  <section id="clients" class="clients">
    <div class="container">
      <div class="row">
        <div
          class="d-flex align-items-center justify-content-center"
          data-aos="zoom-in"
        >
          <img src="assets/img/team/workplace.png" class="img-fluid" alt="" />
          &nbsp;&nbsp;&nbsp;&nbsp; Coming Soon
        </div>
      </div>
    </div>
  </section>
</main>
