<div class="welcome-page">
  <div class="col-md-8 pd-0" style="float: left; height: 100%">
    <div id="branding" class="illustrationClass background-blur"></div>
  </div>

  <div
    class="col-md-4 pd-0 content-box"
    style="padding-top: 50px; float: right; height: 100%"
  >
    <div class="content">
      <img
        src="assets/img/dxc_logo_hz_wht.png"
        width="150px"
        class="user-avatar"
      />
      <h1>Modern Workplace Info Zone</h1>
      <button
        style="margin: auto"
        class="new-dxc-btn btn-login"
        (click)="login()"
      >
        <!-- <i class="fas fa-sign-in-alt"></i> -->
        <!-- <span class="icon material-symbols-outlined">login</span> -->
        Click here to login
      </button>
    </div>
  </div>
</div>
