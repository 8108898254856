import { environment } from "src/environments/environment";
import { EventEmitter, Injectable } from "@angular/core";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";

@Injectable({
    providedIn: "root",
})
export class AppInsightsService {
    appInsights;

    constructor(){
         //Analytics
        this.appInsights = new ApplicationInsights({ config: {
            connectionString: environment.APPLICATIONINSIGHTS_CONNECTION_STRING || 
            'InstrumentationKey=198d7de0-f880-4fc7-885c-0151a4481a2b;IngestionEndpoint=https://southeastasia-1.in.applicationinsights.azure.com/;LiveEndpoint=https://southeastasia.livediagnostics.monitor.azure.com/'
            /* ...Other Configuration Options... */
        } });
        this.appInsights.loadAppInsights();
        // this.appInsights.trackPageView(); // Manually call trackPageView to establish the current user/session/pageview
    }

    logInfo(userInfo:string){
        if(userInfo){
            console.log("Log analytic: "+userInfo);
            this.appInsights.context.user.id = userInfo;
            this.appInsights.context.user.authenticatedId = userInfo;
            this.appInsights.context.user.accountId = userInfo;

            this.appInsights.trackPageView();
            this.appInsights.flush();
        }
    }
}