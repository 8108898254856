<section class="breadcrumbs">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <ol>
        <li><a routerLink="/">Home</a></li>
        <li><a routerLink="../../">Video Library</a></li>
        <li (click)="onBack()" class="link">Videos</li>
        <li>Play Video</li>
      </ol>
    </div>
  </div>
</section>
<div class="video-container">
  <app-video-player
    [options]="videoPlayerOption"
    [video]="video"
  ></app-video-player>
</div>
