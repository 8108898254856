<!-- ======= Home Header Banner | Hero Section======= -->
<section id="hero" class="d-flex align-items-center">
  <div class="container" style="min-height: 400px">
    <div class="row" *ngIf="showBanner">
      <div
        class="col-lg-6 pt-5 mt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center"
      >
        <h1 data-aos="fade-up">
          {{ settingBanner?.title }}
        </h1>
        <h2 data-aos="fade-up" data-aos-delay="100">
          {{ settingBanner?.description }}
        </h2>
        <!-- <div data-aos="fade-up" data-aos-delay="800"> <a href="#about" class="btn-get-started scrollto">Get Started</a> </div>-->
      </div>
      <div
        class="col-lg-6 order-1 mt-5 order-lg-2 hero-img"
        data-aos="fade-left"
        data-aos-delay="100"
      >
        <img
          [src]="trustImage(settingBanner?.image)"
          class="img-fluid animated"
          alt=""
        />
      </div>
    </div>
  </div>
</section>
<!-- End Hero -->
