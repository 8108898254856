<div class="video-item">
  <div class="icon-box">
    <div class="context-menu">
      <button
        mat-icon-button
        [matMenuTriggerFor]="menu"
        aria-label="Example icon-button with a menu"
      >
        <mat-icon>more_vert</mat-icon>
      </button>
      <mat-menu #menu="matMenu">
        <button mat-menu-item>
          <span>Redial</span>
        </button>
        <button mat-menu-item disabled>
          <span>Check voice mail</span>
        </button>
        <button mat-menu-item>
          <span>Disable alerts</span>
        </button>
      </mat-menu>
    </div>
    <div class="icon">
      <img [src]="video?.item?.thumbnailUrl" width="131" height="131" alt="" />
    </div>
    <h4>
      <a routerLink="videos">{{ video?.item?.title }}</a>
    </h4>
    <p>{{ video?.item?.description }}</p>
  </div>
</div>
