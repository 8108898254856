import { IVideoItem } from "src/app/models/video-item";
import { Component, Inject, OnInit } from "@angular/core";
import { FormControl, FormGroup, Validators } from "@angular/forms";
import { MatDialogRef, MAT_DIALOG_DATA } from "@angular/material/dialog";
import { ShareViaEmailService } from "src/app/services/share-via-email.service";
import { MatSnackBar } from "@angular/material/snack-bar";
import { ShareWithComponent } from "../share-with/share-with.component";
import { OktaAuthService } from "src/app/services/app.service";
import { Router } from "@angular/router";

@Component({
  selector: "app-share-video",
  templateUrl: "./share-video.component.html",
  styleUrls: ["./share-video.component.scss"],
})
export class ShareVideoComponent implements OnInit {
  shareForm: FormGroup = new FormGroup({
    email: new FormControl("", [Validators.required, Validators.email]),
    message: new FormControl("", [Validators.required]),
  });
  loading = false;
  firstName: string = '';
  lastName: string = '';
  isAuthenticated = false;
  constructor(
    private _shareViaEmailService: ShareViaEmailService,
    @Inject(MAT_DIALOG_DATA) public data: any,
    private dialogRef: MatDialogRef<ShareWithComponent>,
    private _snackBar: MatSnackBar,
    private oktaAuth: OktaAuthService,
    private _router : Router,
  ) {}

  ngOnInit(): void {
    this.oktaAuth.$isAuthenticated.subscribe((val) => {
      if(val){
        this.isAuthenticated = val;
        this.oktaAuth.oktaAuth.token.getUserInfo().then((res) => {
          if(res.name !== ''){
            this.firstName = res.name;
          }else{
            this.firstName = res.given_name;
            this.lastName = res.family_name;
          }
        }).catch((err) => {
          console.log(err);
          try {
            sessionStorage.setItem('okta-app-url', this._router.url);
            this.oktaAuth.logout()
            this._snackBar.open(" Authorized Error\n Please login to continue", "OK", {
              duration: 2000,
            });
          } catch (error) {
            console.log(error);
            this._router.navigate(['/login']);
          }
      })}
    });
  }
  handleShare() {
    console.log(this.data);
    this.postShare();
  }
  postShare() {
    if (!this.shareForm.valid) {
      this._snackBar.open("Please fill all fields", "OK", {
        duration: 2000,
      });
      return;
    }
    this.loading = true;
    this._shareViaEmailService
      .postShareEmailVideo({
        firstName: this.firstName,
        lastName: this.lastName,
        to: this.shareForm.value.email,
        fileTitle: this.data.name,
        thumbnail: this.data.thumbnail,
        content: this.shareForm.value.message,
        link: this.data.link,
      })
      .subscribe((data) => {
        this.loading = false;
        this.dialogRef.close();
        this._snackBar.open("Email sent successfully", "OK", {
          duration: 2000,
        });
      });
  }
}
