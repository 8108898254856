<section class="breadcrumbs">
  <div class="container">
    <div class="d-flex justify-content-between align-items-center">
      <ol>
        <li><a routerLink="/">Home</a></li>
        <li>Training</li>
      </ol>
    </div>
  </div>
</section>
<!-- End Breadcrumbs Section -->

<!-- ======= Hero Section ======= -->
<section id="hero" class="d-flex align-items-center">
  <div class="container">
    <div class="row">
      <div
        class="col-lg-6 pt-5 pt-lg-0 order-2 order-lg-1 d-flex flex-column justify-content-center"
      >
        <h1 data-aos="fade-up">
          {{ banner.title }}
        </h1>
        <h2 data-aos="fade-up" data-aos-delay="400">
          {{ banner.description }}
        </h2>
        <!-- <div data-aos="fade-up" data-aos-delay="800"> <a href="#about" class="btn-get-started scrollto">Get Started</a> </div>-->
      </div>
      <div
        class="col-lg-6 order-1 order-lg-2 hero-img"
        data-aos="fade-left"
        data-aos-delay="200"
      >
        <img
          [src]="trustImage(banner.image)"
          class="img-fluid animated"
          alt=""
        />
      </div>
    </div>
  </div>
</section>
<!-- End Hero -->

<main id="main">
  <!-- ======= Team Section ======= -->
  <section id="services" class="services section-bg">
    <div class="container" data-aos="fade-up">
      <div class="row">
        <div class="col-lg-9">
          <div class="row">
            <ng-container *ngFor="let video of videos">
              <div
                class="training-item col-lg-4 col-md-6 d-flex align-items-stretch mb-3"
              >
                <div class="icon-box">
                  <div class="icon">
                    <img [src]="trustImage(video.thumbnail)" alt="" />
                  </div>

                  <h4>
                    <a
                      class="cut-text"
                      [routerLink]="video.type"
                      [queryParams]="{ filter: video.filter }"
                      >{{ video.name }}</a
                    >
                  </h4>
                  <p class="cut-text">{{ video.description }}</p>
                </div>
              </div>
            </ng-container>
          </div>
        </div>
        <div class="col-lg-3">
          <div id="footer">
            <div class="footer-top">
              <div class="container">
                <div class="row">
                  <div class="footer-links">
                    <h4>Additional Training Resources</h4>
                    <ul>
                      <li *ngFor="let menu of rightMenu">
                        <i class="bx bx-chevron-right"></i>&nbsp;&nbsp;
                        <ng-container
                          *ngIf="menu.type === 'Inner-Page'; else directLink"
                        >
                          <ng-container
                            *ngIf="menu.typeFiler !== ''; else noFilter"
                          >
                            <a
                              class="cut-text"
                              [routerLink]="menu.link"
                              [title]="menu.shortDescription"
                              [queryParams]="{ type: menu.typeFilter }"
                              >{{ menu.name }}</a
                            >
                          </ng-container>
                          <ng-template #noFilter>
                            <a
                              class="cut-text"
                              [routerLink]="menu.link"
                              [title]="menu.shortDescription"
                              >{{ menu.name }}</a
                            >
                          </ng-template>
                        </ng-container>
                        <ng-template #directLink>
                          <a
                            class="cut-text"
                            href="{{ menu.link }}"
                            [title]="menu.shortDescription"
                            target="_blank"
                            >{{ menu.name }}</a
                          >
                        </ng-template>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </section>
  <!-- End Team Section -->

  <!-- ======= Clients Section ======= -->
  <section id="clients" class="clients">
    <div class="container">
      <div class="row">
        <div
          class="d-flex align-items-center justify-content-center"
          data-aos="zoom-in"
        >
          <img src="assets/img/team/workplace.png" class="img-fluid" alt="" />
          &nbsp;&nbsp;&nbsp;&nbsp; Coming Soon
        </div>
      </div>
    </div>
  </section>
  <!-- End Clients Section -->
</main>
