import { Component, OnInit } from "@angular/core";

@Component({
  selector: "app-video-download-snackbar",
  templateUrl: "./video-download-snackbar.component.html",
  styleUrls: ["./video-download-snackbar.component.scss"],
})
export class VideoDownloadSnackbarComponent implements OnInit {
  constructor() {}

  ngOnInit(): void {}
}
